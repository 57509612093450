import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { getScheduledCampaignSingle } from '@/application/services/statistic.js'
import { useTableHandlers } from '@/application/composables/messagesTable.js'
import { useHumanDate } from '@/application/composables/humanDate.js'

export function useSingleScheduledCampaign() {
  const i18n = useI18n()
  const isLoading = ref(false)
  const { humanDateTime } = useHumanDate()
  const { openModal } = useTableHandlers()
  const getSingleScheduledCampaign = (campaignId, license, mediaUrl) => {
    isLoading.value = true
    getScheduledCampaignSingle(campaignId, {
      Authorization: license.license_key,
      'Loop-Secret-Key': license.secret_api_key,
    })
      .then(({ data }) => {
        const obj = {
          sendFromDate: humanDateTime(data.send_from_date, i18n.locale),
          status: i18n.t(`scheduledCampaignStatus.${data.status}`),
          recipients: data.recipients,
          subscribedRecipients: data.subscribed_recipients,
          unsubscribedRecipients: data.unsubscribed_recipients,
          complainedRecipients: data.complained_recipients,
          sandbox: data.sandbox ? i18n.t('yes') : i18n.t('no'),
          date: humanDateTime(data.create_date, i18n.locale),
          sendFromTime: `${data.send_from_time} - ${data.send_to_time}`,
        }
        if (mediaUrl !== undefined) obj.mediaURL = mediaUrl
        isLoading.value = false
        openModal(obj)
      })
      .catch(err => {
        isLoading.value = false
        console.error(err)
      })
  }
  return {
    getSingleScheduledCampaign,
    isLoading,
  }
}
