<template>
  <div class="table-responsive position-relative" v-if="list.length > 0">
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <table class="table table-striped table-hover" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :class="item === 'text' ? 'col-md-4' : ''" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id" @click="triggerRequest(row.campaign_id, row.media_url)">
          <td :data-label="$t('recipient')">{{ row.recipients }}</td>
          <td class="sentMessageTable-textWrapper  d-none d-md-block" :data-label="$t('text')">
            <span class="sentMessageTable-text">{{ row.text }}</span>
          </td>
          <td class="d-block d-md-none" :data-label="$t('text')">
            {{ row.text }}
          </td>
          <td :data-label="$t('status')">{{ $t(`scheduledCampaignStatus.${row.status}`) }}</td>
          <td :data-label="$t('sandbox')">{{ row.sandbox ? $t('yes') : $t('no') }}</td>
          <td :data-label="$t('date')" class="sentMessageTable-date">{{ humanDateTime(row.create_date, $i18n.locale) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useHumanDate } from '@/application/composables/humanDate.js'
import { useSingleScheduledCampaign } from '@/application/composables/getScheduledCampaign.js'

export default {
  name: 'SentMessagesTable',
  components: {
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    license: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const { humanDateTime } = useHumanDate()
    const {
      getSingleScheduledCampaign,
      isLoading,
    } = useSingleScheduledCampaign()
    const triggerRequest = (campaignId, mediaUrl) => {
      getSingleScheduledCampaign(campaignId, props.license, mediaUrl)
    }
    const fields = ['recipients', 'text', 'status', 'sandbox', 'date']
    return {
      humanDateTime,
      fields,
      triggerRequest,
      isLoading,
    }
  },
}
</script>
